.our-team-cards-main.desktop{
    flex-wrap: wrap;
}

.our-team-main.desktop > .header{
    font-size: 4vmin;
    padding: 2vmin;
    height: 10vmin;
}

.our-team-cards-main.desktop > .body{
    padding: 2vmin;
}

.our-team-cards-main.desktop > .body > .name{
    font-size: 2vmin;
    margin: 1vmin;
}

.our-team-cards-main.desktop > .body > .position{
    font-size: 1.6vmin;
    margin: 1vmin;
}

.our-team-cards-main.desktop > .body > .photo > .image-container{
    border-radius: 3vmin;
    overflow: hidden;
    height: 23vmin;
    width: 23vmin;
}

.our-team-cards-main.desktop > .body > .shapes > .image-container{
    bottom: 12vmin;
    width: 23vmin;
}

.our-team-cards-main.desktop > .body > .images{
    height: 3vmin;
    margin: 1vmin;
    justify-content: space-evenly;
}

.our-team-cards-main.desktop > .body > .images > .image-container{
    height: 100%;
}