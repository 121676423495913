

.sidebar.mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 100%;
}

.sidebar.mobile > .switch{
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.sidebar.mobile > .switch.full > .body{
  margin-left: 2.6vh;
}

.sidebar.mobile > .switch.full > .body > .image-container{
  height: 2.7vh;
}

.sidebar.mobile > .switch.full > .body > .text{
  padding-left: 4vw;
  font-size: 2.7vh;
  line-height: 2.7vh;
  font-weight: 600;
}

.sidebar.mobile > .switch.mini{
  flex-direction: row;
  justify-content: center;
  flex: 0;
}

.sidebar.mobile > .switch.mini > .body > .image-container{
  height: 5.5vw;
  padding-left: 4vw;
  padding-right: 4vw;
}

.sidebar.mobile > .switch.mini > .body > .text{
  font-size: 0;
}

.sidebar.mobile > .project-request{
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.sidebar.mobile .project-request > .header{
  font-size: 5.8vw;
  font-weight: 600;
}

.sidebar.mobile > .project-request > .header > .underline{
  padding-top: 1.3vh;
  height: 0.8vh;
  padding-bottom: 4vw;
}

.sidebar.mobile > .project-request > .email{
  display: flex;
  border-radius: 1.6vw;
  font-size: 4vw;
  padding: 2.4vw;
  line-height: 6vw;
  height: 6vw;
  margin-bottom: 2vw;
  width: 89%;
}

.sidebar.mobile > .project-request > .message{
  display: flex;
  flex: 2;
  border-radius: 1.6vw;
  font-size: 4vw;
  padding: 2.4vw;
  line-height: 8vw;
  margin-bottom: 2vw;
  width: 89%;
}

.sidebar.mobile > .project-request > .contact-us{
  border-radius: 1.6vw;
  padding: 2vw;
  height: 5.5vw;
  max-height: 6.5vw;
  line-height: 100%;
  width: 90%;
}

.sidebar.mobile > .project-request > .contact-us > .image-container{
  height: 2vh;
  /* line-height: 3vh; */
}

.sidebar.mobile .project-request > .submit-circle{
  height: 15vh;
  width: 15vh;
  border-radius: 7.5vh;
}

.sidebar.mobile .project-request > .submit-circle > .image-container{
  height: 4.5vh;
}

.sidebar.mobile > .project-request > .in-contact{
  font-size: 3.2vw;
}

.sidebar.mobile > .affiliates {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 2.5vw;
  margin-top: 2.5vw;
  height: 5.8vw;
}

.sidebar.mobile > .affiliates > .image-container{
  display: flex;
  justify-content: center;
}


.sidebar.mobile > .copyright{
  font-size: 3.4vw;
  margin-bottom: 3.4vw;
}