.navbar {
    display:flex;
    align-items: center;
    user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout:none;
    -ms-user-select:none;
    /* font-family:  sans-serif; */
    font-family: "Mont Semi Bold", sans-serif;
    font-weight: 600;


}



