.sidebar {
    background-color: #3F3D56;
    height: 100%;
    user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout:none;
    -ms-user-select:none;
    overflow: hidden;
}

.sidebar > .top-section{
    background-color: #2D2B43;
}


.sidebar > .switch{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.sidebar > .switch > .body{
    display: flex;
    justify-content: flex-start;
}

.sidebar > .switch > .body > .image-container{
    height: 3vmin;
}

.sidebar > .switch > .body > .text{
    color: white;
    font-weight: 600;
}

.sidebar > .switch > .body > .text.active{
    color: #FF8A64
}

.sidebar > .project-request{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.sidebar .project-request > .header{
    color: white;
    font-weight: 600;
}

.sidebar > .project-request > .header > .underline{
    display: flex;
    flex-direction: row;
}

.sidebar > .project-request > .header > .underline > .left{
    background-color: #2D2B43;
    height: 100%;
    flex: 3;
}

.sidebar > .project-request > .header > .underline > .right{
    background-color: #FF8A64;
    height: 100%;
    flex: 1;
}

.sidebar > .project-request > .email{
    border-width: 0;
    resize: none;
}

.sidebar > .project-request > .message{
    border-width: 0;
    resize: none;
}

.sidebar .project-request > .submit-circle{
    display: flex;
    background-color: #2D2B43;
    border-style: dashed;
    border-color: #43CEAF;
    justify-content: center;
    align-items: center;
}

.sidebar .project-request > .in-contact.red,
.sidebar .project-request > .submit-circle.red{
    border-color: red;
    color: red;
}

.sidebar .project-request > .submit-circle.red > .icon{
    font-size: 5vh;
}


.sidebar > .project-request > .contact-us{
    display: flex;
    background-color: #FF8A64;
    color: white;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sidebar > .project-request > .in-contact{
    color: #43CEAF;
    font-weight: 600;
    text-align: center;
}

