.frame {
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.frame > .non-sidebar {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.frame > .non-sidebar > .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

