img{
    display: block;
    height: 100%;
}

html, body, #root{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

textarea:focus, input:focus{
    outline: 0;
}

.app-main.desktop{
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    height: 100%;
}