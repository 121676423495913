
.projects-main > .body {
    margin: 4vmin;
}

.projects-main > .body > .body-top{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: center;
    align-content: center;
    margin-bottom: 2vmin;
}

.projects-main > .body > .body-top > .phone{
    position: relative;
    top: 15%;
    width: 100%;
    left: 25%;
}

.projects-main> .body > .body-top > .shapes{
    position: relative;
    width: 150%;
    right: 25%;
}

.projects-main> .body > .body-top > .phone > .image-container{
    position: relative;
    height: 150%;
    width: 0;
} 


.projects-main > .body > .body-top > .shapes > .image-container{
    position: relative;
    height: 60%;
    width: 0;
    z-index: 1;
}

.projects-main > .body > .images > .image-container {
    flex: 0 1 35%;
}

.projects-main > .body > .body-main > .title{
    font-weight: 600;
}

.projects-main > .body > .images{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 5vh;
}


.projects-main {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.projects-main.desktop{
    max-width: 70vw;
    flex: 1;
}

.projects-main.desktop > .body{
    width: 52vmin;
}

.projects-main.desktop > .body > .body-top {
    height: 35vmin;
    border-radius: 2vmin;
}

.projects-main.desktop > .body > .body-main > .title{
    font-size: 3vmin;
    margin-bottom: 2vmin;
}

.projects-main.desktop > .body > .body-main > .text{
    font-size: 2.2vmin;
    margin-bottom: 2vmin;
}

.projects-main.desktop > .body > .images > .image-container{
    height: 5.4vmin;
}

.projects-main.mobile > .body{
    width: 90vw;
}

.projects-main.mobile > .body > .body-top {
    height: 60vw;
    border-radius: 3vmin;
}

.projects-main.mobile > .body > .body-main > .title{
    font-size: 7vw;
    margin-bottom: 4vw;
}

.projects-main.mobile > .body > .body-main > .text{
    font-size: 4vw;
    margin-bottom: 4vw;
}

.projects-main.mobile > .body > .images > .image-container{
    height: 4.5vh;
}




