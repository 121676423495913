@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
html, body, .root {
  height: 100%;
} 

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
    display:flex;
    align-items: center;
    user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout:none;
    -ms-user-select:none;
    /* font-family:  sans-serif; */
    font-family: "Mont Semi Bold", sans-serif;
    font-weight: 600;


}




.navbar.desktop{
    display: flex;
    flex-direction: row;
    height: 7vh;
    align-items: center;
}

.navbar.desktop > .blast-logo{
    display: flex;
    flex-direction: row;
    background-color: #2D2B43;
    width: 29.3vmin;
    height: 100%;
    align-items: center;
    /* text-align: center; */
}

.navbar.desktop > .blast-logo > .image-container{
    height: 5vh;
    padding: 1vh;
    margin-left: 1vh;
    /* margin-right: 1vmin; */
}

.navbar.desktop > .blast-logo > .title{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: white;
    font-size: 2.2vh;
    line-height: 2.2vh;
    width: 4vw;
}

.navbar.desktop > .right-side {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex: 1 1;
    height: 7vh;
    /* line-height: 6vh; */
    border-bottom: #DCDCDC 0.1vmin solid;
}

.navbar.desktop > .right-side > .left-buffer{
    display: flex;
    flex: 1 1;
    max-width: 60vmin;
}

.navbar.desktop > .right-side > .right-buffer{
    display: flex;
    max-width: 40vmin;
}

.navbar.desktop > .right-side > .affiliates{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 20vh;
    height: 3vh;
}
.navbar.mobile{
    background-color:#3F3D56;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    /* height: 9%; */
    height: 20vw;
}

.navbar.mobile > .logo{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar.mobile > .logo > .image-container{
    height: 12vw;
    /* height: 6vh; */
    margin: 2vw;
    padding-left: 2vw;
}

.navbar.mobile > .logo > .title{
    display: flex;
    flex-wrap: wrap;
    width: 10vh;
    color: white;
    line-height: 5vw;
    font-size: 5.6vw;
}

.navbar.mobile > .sidebar-icon {
    font-size: 8vw;
    /* margin: 2vw; */
    color: white;
    padding: 6vw;
}
.sidebar {
    background-color: #3F3D56;
    height: 100%;
    user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout:none;
    -ms-user-select:none;
    overflow: hidden;
}

.sidebar > .top-section{
    background-color: #2D2B43;
}


.sidebar > .switch{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.sidebar > .switch > .body{
    display: flex;
    justify-content: flex-start;
}

.sidebar > .switch > .body > .image-container{
    height: 3vmin;
}

.sidebar > .switch > .body > .text{
    color: white;
    font-weight: 600;
}

.sidebar > .switch > .body > .text.active{
    color: #FF8A64
}

.sidebar > .project-request{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: space-between;
    align-items: center;
}

.sidebar .project-request > .header{
    color: white;
    font-weight: 600;
}

.sidebar > .project-request > .header > .underline{
    display: flex;
    flex-direction: row;
}

.sidebar > .project-request > .header > .underline > .left{
    background-color: #2D2B43;
    height: 100%;
    flex: 3 1;
}

.sidebar > .project-request > .header > .underline > .right{
    background-color: #FF8A64;
    height: 100%;
    flex: 1 1;
}

.sidebar > .project-request > .email{
    border-width: 0;
    resize: none;
}

.sidebar > .project-request > .message{
    border-width: 0;
    resize: none;
}

.sidebar .project-request > .submit-circle{
    display: flex;
    background-color: #2D2B43;
    border-style: dashed;
    border-color: #43CEAF;
    justify-content: center;
    align-items: center;
}

.sidebar .project-request > .in-contact.red,
.sidebar .project-request > .submit-circle.red{
    border-color: red;
    color: red;
}

.sidebar .project-request > .submit-circle.red > .icon{
    font-size: 5vh;
}


.sidebar > .project-request > .contact-us{
    display: flex;
    background-color: #FF8A64;
    color: white;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sidebar > .project-request > .in-contact{
    color: #43CEAF;
    font-weight: 600;
    text-align: center;
}


.sidebar.desktop{
  width: 29.3vmin;
}

.sidebar > .copyright{
  font-weight: 600;
  color: #B0B0CB;
  text-align: center;
}

.sidebar.desktop > .switch{
  margin-top: 2vh;
  height: 35vh;
}

.sidebar.desktop > .switch > .body{
  padding: 2.5vh;
  cursor: pointer;
}

.sidebar.desktop > .switch > .body > .image-container{
  height: 3vmin;
}

.sidebar.desktop > .switch > .body > .text{
  padding-left: 2vmin;
  line-height: 3vmin;
  font-size: 2vmin;
  font-weight: 600;
}

.sidebar.desktop > .project-request{
  padding: 2.5vh;
  margin-top: 5vh;
  height: 40vh;
}

.sidebar.desktop .project-request > .header{
  font-size: 2.2vmin;
  font-weight: 600;
}

.sidebar.desktop > .project-request > .header > .underline{
  height: 0.6vh;
  padding-top: 1vh;
  padding-bottom: 2.5vh;
}

.sidebar.desktop > .project-request > .email{
  display: flex;
  border-radius: 0.6vh;
  font-size: 1.7vmin;
  padding: 1vh;
  height: 3vmin;
  line-height: 3vmin;
  width: 90%;
  margin-bottom: 2.5vh
}

.sidebar.desktop > .project-request > .message{
  display: flex;
  border-radius: 0.6vh;
  font-size: 1.7vmin;
  padding: 1vh;
  line-height: 3vmin;
  width: 90%;
  flex: 1 1;
  margin-bottom: 2.5vh
}

.sidebar.desktop > .project-request > .contact-us{
  border-radius: 0.6vh;
  padding: 1vh;
  height: 3vh;
  line-height: 3vh;
  font-size: 1.7vmin;
  width: 90%;
}

.sidebar.desktop > .project-request > .contact-us > .image-container{
  height: 2vh;
  line-height: 3vh;
}

.sidebar.desktop .project-request > .submit-circle{
  height: 15vh;
  width: 15vh;
  border-radius: 7.5vh;
}

.sidebar.desktop .project-request > .submit-circle > .image-container{
  height: 4vh;
}

.sidebar.desktop > .project-request > .in-contact{
  font-size: 1.4vmin;
}

.sidebar.desktop > .affiliates {
  height: 0;
  overflow: hidden;
}

.sidebar.desktop > .copyright{
  padding-top: 2vh;
  font-size: 1.4vmin;
}


.sidebar.mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;
}

.sidebar.mobile > .switch{
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.sidebar.mobile > .switch.full > .body{
  margin-left: 2.6vh;
}

.sidebar.mobile > .switch.full > .body > .image-container{
  height: 2.7vh;
}

.sidebar.mobile > .switch.full > .body > .text{
  padding-left: 4vw;
  font-size: 2.7vh;
  line-height: 2.7vh;
  font-weight: 600;
}

.sidebar.mobile > .switch.mini{
  flex-direction: row;
  justify-content: center;
  flex: 0 1;
}

.sidebar.mobile > .switch.mini > .body > .image-container{
  height: 5.5vw;
  padding-left: 4vw;
  padding-right: 4vw;
}

.sidebar.mobile > .switch.mini > .body > .text{
  font-size: 0;
}

.sidebar.mobile > .project-request{
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.sidebar.mobile .project-request > .header{
  font-size: 5.8vw;
  font-weight: 600;
}

.sidebar.mobile > .project-request > .header > .underline{
  padding-top: 1.3vh;
  height: 0.8vh;
  padding-bottom: 4vw;
}

.sidebar.mobile > .project-request > .email{
  display: flex;
  border-radius: 1.6vw;
  font-size: 4vw;
  padding: 2.4vw;
  line-height: 6vw;
  height: 6vw;
  margin-bottom: 2vw;
  width: 89%;
}

.sidebar.mobile > .project-request > .message{
  display: flex;
  flex: 2 1;
  border-radius: 1.6vw;
  font-size: 4vw;
  padding: 2.4vw;
  line-height: 8vw;
  margin-bottom: 2vw;
  width: 89%;
}

.sidebar.mobile > .project-request > .contact-us{
  border-radius: 1.6vw;
  padding: 2vw;
  height: 5.5vw;
  max-height: 6.5vw;
  line-height: 100%;
  width: 90%;
}

.sidebar.mobile > .project-request > .contact-us > .image-container{
  height: 2vh;
  /* line-height: 3vh; */
}

.sidebar.mobile .project-request > .submit-circle{
  height: 15vh;
  width: 15vh;
  border-radius: 7.5vh;
}

.sidebar.mobile .project-request > .submit-circle > .image-container{
  height: 4.5vh;
}

.sidebar.mobile > .project-request > .in-contact{
  font-size: 3.2vw;
}

.sidebar.mobile > .affiliates {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 2.5vw;
  margin-top: 2.5vw;
  height: 5.8vw;
}

.sidebar.mobile > .affiliates > .image-container{
  display: flex;
  justify-content: center;
}


.sidebar.mobile > .copyright{
  font-size: 3.4vw;
  margin-bottom: 3.4vw;
}
.frame {
    height: 100%;
    width: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.frame > .non-sidebar {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.frame > .non-sidebar > .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
}


.frame.desktop > .non-sidebar > .content {
    min-width: 20vw;
    padding: 1vmin;
}
.frame.mobile {
  width: 170vw;
  /* left: 100vw; */
  position: relative;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  height: 92%;
}

.frame.mobile.sidebar-open {
  -webkit-transform: translate(-70vw);
          transform: translate(-70vw)
}

.frame.mobile > .overlay{
  z-index: 1;
  /* background-color: green; */
  width: 100%;
  position: absolute;
  height: 100%;
}

.frame.mobile > .sidebar-container {
  width: 70vw;
  /* background: blue; */
  display:flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
}

.frame.mobile > .non-sidebar {
  width: 100vw;
}


.search-area {
    display: flex;
    flex-direction: row;
    height: inherit;
    line-height: inherit;
}

.search-area > .input{
    display: flex;
    flex: 1 1;
    border-width: 0;
    font-size: inherit;
    height: inherit;
    line-height: inherit;
    
}

.search-area > .input:focus{
    outline: none;
}

.search-bar.desktop{
    padding-top: 2vmin;
    padding-bottom: 2vmin;
    padding-left: 1vmin;
    padding-right: 2vmin;
    line-height: 3vmin;
}

.search-area.desktop{
    font-size: 2.5vmin;
    border-radius: 0.5vmin;
    box-shadow: 0px 0px 0.5vmin #000000;
}

.search-area.desktop > .text{
    padding: 1vmin;
}

.search-area.desktop > .button{
    background-color: #43CEAF;
    border-radius: 0 0.5vmin 0.5vmin 0;
    width: 5vmin;
}

.search-area.desktop > .button > .search-icon{
    color: white;
    padding: 1vmin;
}

.search-bar.mobile{
    padding: 4vw;
    line-height: 6vw;
}

.search-area.mobile{
    font-size: 4vw;
    border-radius: 1vw;
    box-shadow: 0px 0px 1vw #000000;
}

.search-area.mobile > .text{
    padding: 2vw;
}

.search-area.mobile > .button{
    background-color: #43CEAF;
    border-radius: 0 1vw 1vw 0;
    width: 9vw;
}

.search-area.mobile > .button > .search-icon{
    color: white;
    padding: 2vw;
}

.open-source-card-main,
.open-source-card-main > .body, 
.open-source-card-main > .body > .body-main, 
.open-source-card-main > .images{
    display: flex;
}

.open-source-card-main > .body > .body-main > .title{
    font-weight: 600
}


.open-source-card-main >  .images{
    flex-direction: row;
}


.open-source-card-main > .images > .star {
    display: flex;
}

.open-source-card-main > .images > .star > .icon{
    color: #FCB059;
}

.open-source-card-main{
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vmin;
    flex: 1 1;
}

.open-source-main.desktop{
    width: 50vmin;
    max-width: 120vmin;
    flex: 1 1;
}

.open-source-card-main.desktop {
    flex-direction: row;
    height: 5vmin;
}

.open-source-card-main.desktop > .body > .side-icon > .image-container{
    height: 3vmin;
    width: 4vmin;
    padding-left: 1vmin;
    padding-right: 1vmin;
}

.open-source-card-main.desktop > .body > .body-main{
    flex-direction: column;
    justify-content: center;
    font-size: 1.5vmin;
    height: 3vmin;
}

.open-source-card-main.desktop > .images{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 5vmin;
    flex-basis: 35%;
}

.open-source-card-main.desktop > .images > .star > .text{
    line-height: 5vmin;
    font-size: 2vmin;
}

.open-source-card-main.desktop > .images > .star > .icon{
    font-size: 2vmin;
    line-height: 5vmin;
    width: 3vmin;
}

.open-source-card-main.desktop> .images > .image-container{
    padding: 1vmin;
}

.open-source-card-main.mobile{
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2vw;
}

.open-source-card-main.mobile > .body{
    flex-direction: row;
    /* height: 20vw; */
}

.open-source-card-main.mobile > .body > .body-main{
    flex-direction: column;
}

.open-source-card-main.mobile > .body > .side-icon{
    width: 12vw;
    padding-top: 1vw;
}

.open-source-card-main.mobile > .body > .side-icon > .image-container{
    height: 6vw;
    padding: 2vw;
}

.open-source-card-main.mobile > .images{
    height: 6vw;
    padding-left: 12vw;
}

.open-source-card-main.mobile > .images > .star > .icon{
    font-size: 4vw;
    width: 6vw;
}

.open-source-card-main.mobile > .images > .star > .text{
    padding-right: 8vw;
}

.open-source-card-main.mobile> .images > .image-container{
    height: 100%;
    padding-left: 10vw;
}




.blog-card-main,
.blog-card-main > .body, 
.blog-card-main > .body > .body-main,
.blog-card-main > .body > .body-right > .text {
    display: flex;
}

.blog-card-main > .body > .body-main > .title{
    font-weight: 600
}

.blog-card-main > .body > .body-main > .name{
    color: #FF8A64
}

.blog-card-main.desktop{
    justify-content: space-between;
    padding-top: 2vmin;
    flex-direction: row;
}

.blog-card-main > .body > .body-right > .text{
    flex-direction: row;
}

.blog-card-main > .body > .body-right > .text > .text-container{
    background-color: #FFEAE4;
    color: #FF8A64;
}


.blog-card-main > .body > .body-right > .go-to{
    color: #43CEAF;
}

.blog-main.desktop{
    width: 50vmin;
    max-width: 120vmin;
    flex: 1 1;
}

.blog-card-main.desktop > .body > .body-right > .go-to{
    padding-right: 2.1vmin;
    text-align: end;
}

.blog-card-main.desktop> .body{
    justify-content: space-between;
    width: 100%;
}

.blog-card-main.desktop> .logo {
    height: 8vmin;
}

.blog-card-main.desktop> .logo > .image-container{
    height: 100%;
    padding-left: 1vmin;
    padding-right: 2vmin;
}

.blog-card-main.desktop > .body > .body-main{
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.5vmin;
}

.blog-card-main.desktop > .body > .body-right{
    font-size: 1.5vmin;
}

.blog-card-main.desktop > .body > .body-right > .text > .text-container{
    margin-right: 2vmin;
    margin-bottom: 1vmin;
    padding-left: 1vmin;
    padding-right: 1vmin;
    padding-top: 0.5vmin;
    padding-bottom: 0.5vmin;
    border-radius: 0.7vmin;
}

.blog-card-main.desktop > .body > .body-right > .go-to{
    padding-right: 2.1vmin;
    text-align: end;
}

.blog-card-main.mobile{
    flex-direction: column;
    justify-content: space-between;
    padding-top: 5vw;
    padding-left: 2vw;
    padding-right: 5vw;
}

.blog-card-main.mobile > .body{
    flex-direction: column;
}

.blog-card-main.mobile > .body > .body-main{
    flex-direction: column;
}

.blog-card-main.mobile > .body > .body-main > .name{
    margin-top: 2vw;
}

.blog-card-main.mobile > .body > .body-right{
    font-size: 3.2vw;
}

.blog-card-main.mobile > .body > .body-right > .text > .text-container{
    margin-right: 3vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
    padding: 2vw;
    border-radius: 2vw;
}

.blog-card-main.mobile > .logo{
    padding-top: 1vw;
}

.blog-card-main.mobile > .logo > .image-container{
    height: 18vw;
    margin-bottom: 3vw;
}



.home {
    display: flex;
    flex-direction: column;
    font-family: 'Source Sans Pro', sans-serif;
}

.home > .banner,
.home > .projects > .project-main {
    display: flex;
}

.home > .banner > .body > .header,
.home > .banner > .body > .bullet-point-section > .bullet-point-header,
.home > .projects > .top-section > .header,
.home > .open-source > .top-section > .header,
.home > .blog > .top-section > .header,
.home > .projects > .project-main > .body > .title{
    font-weight: 600;
}

.home > .banner > .body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.home > .banner > .body > .header {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.home > .banner > .body > .bullet-point-section {
    display: flex;
    flex: 1.8 1;
    flex-direction: column;
    justify-content: space-evenly
}

.home > .banner > .body > .bullet-point-section > .bullet-points > .bullet-point {
    display: flex;
    flex-direction: row;
}

.home > .blog > .top-section > .underline,
.home > .open-source > .top-section > .underline,
.home > .projects > .top-section > .underline {
    display: flex;
    flex-direction: row;
}

.home > .blog > .top-section > .underline > .left,
.home > .open-source > .top-section > .underline > .left, 
.home > .projects > .top-section > .underline > .left{
    height: 100%;
    background-color: #3F3D56;
}

.home > .open-source > .top-section > .underline > .left{
    flex: 3 1;
}
.home > .projects > .top-section > .underline > .left{
    flex: 2 1;
}
.home > .blog > .top-section > .underline > .left{
    flex: 1.5 1;
}

.home > .blog > .top-section > .underline > .right,
.home > .open-source > .top-section > .underline > .right,
.home > .projects > .top-section > .underline > .right{
    height: 100%;
    background-color: #FF8A64;
    flex: 1 1
}

.home > .projects > .project-main > .phone{
    z-index: 1;
}

.home > .projects > .project-main > .body{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1 1;
    min-width: 30vmin;
}

.home > .projects > .project-main > .body > .images {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    flex-wrap: wrap;
}

.home > .projects > .project-main > .body > .images > .image-container{
    flex: 0 1 30%;
    padding: 0.7vmin;
    /* flex-wrap: wrap; */
}

.home > .projects > .see-more-container{
    display: flex;
    justify-content: center;
}

.home > .projects > .see-more-container > .see-more{
    background-color: #43CEAF;
    color: white;
    text-align: center;
}

.home > .blog > .see-more,
.home > .open-source > .see-more{
    color: #43CEAF;
    font-weight: 600;
} 







.home.desktop{
    /* padding-top: 4vmin; */
    width: 50vmin;
    max-width: 120vmin;
    flex: 1 1;
}

.home.desktop > .banner {
    flex-direction: row;
    padding: 2vmin;
    justify-content: space-around;
    flex-wrap: wrap;
    flex: 0 0 auto;
}

.home.desktop > .blog > .top-section > .header,
.home.desktop > .open-source > .top-section > .header,
.home.desktop > .projects > .top-section > .header,
.home.desktop > .banner > .body > .header {
    font-size: 4vmin;
}

.home.desktop > .banner > .body {
    width: 40vmin;
    min-height: 35vmin;
}

.home.desktop > .banner > .buffer{
    display: flex;
    flex: 1 1;
    max-width: 30vmin;
}

.home.desktop > .bullet-point-section {
    padding: 3vmin 5vmin;
}


.home.desktop > .projects > .project-main > .body > .title, 
.home.desktop > .bullet-point-section > .bullet-point-header {
    font-size: 3vmin;
}

.home.desktop > .bullet-point-section > .bullet-points > .bullet-point {
    font-size: 1.8vmin;
    line-height: 3vmin;
    display: flex;
}

.home.desktop > .bullet-point-section > .bullet-points > .bullet-point {
    line-height: 4vmin;
}

.home.desktop > .bullet-point-section > .bullet-points > .bullet-point > img {
    width: 2vmin;
    padding: 1vmin;
}

.home.desktop > .bullet-point-section > .bullet-points > .bullet-point > .point {
    line-height: 4vmin;
}

.home.desktop > .banner > .splash {
    /* margin-left: 20vmin; */
    /* display: flex; */
    height: 45vmin;
    /* flex: 1; */
}

.home.desktop > .blog,
.home.desktop > .open-source {
    padding: 2vmin;
    margin: 2vmin;
    width: auto;
}


.home.desktop > .projects{
    padding: 2vmin;
    margin: 2vmin;
    max-width: 60vw;
}

.home.desktop > .projects > .top-section {
    width: 22vmin;
}

.home.desktop > .blog > .top-section > .underline,
.home.desktop > .open-source > .top-section > .underline,
.home.desktop > .projects > .top-section > .underline {
    height: 0.4vmin;
    margin-top: 1vmin;
}


.home.desktop > .projects > .project-main{
    height: 50vmin;
    max-width: 100vmin;
    justify-content: center;
    margin: 5vmin;
}


.home.desktop > .projects > .project-main.is-left{
    flex-direction: row-reverse;
}

.home.desktop > .projects > .project-main.is-right{
    flex-direction: row;
}

.home.desktop > .projects > .project-main > .shapes{
    width: 0vmin;
    height: 30vmin;
    position: relative;
}

.home.desktop > .projects > .project-main.is-right > .shapes{
    right: 8vmin;
    top: 14vmin;
}

.home.desktop > .projects > .project-main.is-left > .shapes{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    left: 13.5vmin;
    top: 10vmin;
}

.home.desktop > .projects > .project-main > .body {
    width: 50vmin;
    padding: 5vmin;
}


.home.desktop > .projects > .project-main > .body > .text {
    font-size: 1.7vmin;
}

.home.desktop > .projects > .project-main > .body > .images > .image-container {
    height: 5vmin;
}

.home.desktop > .projects > .project-main > .phone {
    width: 20vmin;
    padding-left: 5vmin;
    padding-right: 5vmin;
}


.home.desktop > .projects > .see-more-container > .see-more{
    font-size: 1.7vmin;
    height: 4vmin;
    line-height: 4vmin;
    width: 22vmin;
    border-radius: 1vmin;
    cursor: pointer;
}
.home.desktop > .open-source > .top-section{
    width: 30vmin;
}

.home.desktop > .open-source > .see-more{
    margin: 4vmin;
    font-size: 2vmin;
    cursor: pointer;
}

.home.desktop > .blog > .top-section{
    width: 15vmin;
}

.home.desktop > .blog > .see-more{
    margin: 5vmin;
    font-size: 2vmin;
    cursor: pointer;
}







.home.mobile{
    padding: 8vw;
}

.home.mobile > .banner {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.home.mobile > .open-source > .top-section > .header,
.home.mobile > .blog > .top-section > .header,
.home.mobile > .projects > .top-section > .header,
.home.mobile > .banner > .body > .header {
    font-size: 9vw;
}

.home.mobile > .open-source:last-child {
    padding-bottom: 30px;
}

.home.mobile > .projects > .top-section {
    margin-bottom: 30px;
}

.home.mobile > .bullet-point-section{
    margin-top: 10vw;
}

.home.mobile > .projects > .project-main > .body > .title, 
.home.mobile > .bullet-point-section > .bullet-point-header {
    font-size: 5vw;
    padding-top: 20px;
}

.home.mobile > .bullet-point-section > .bullet-points > .bullet-point {
    font-size: 4.5vw;
    line-height: 9vw;
    display: flex;
    padding: 10px 0;
}

.home.mobile > .bullet-point-section > .bullet-points > .bullet-point > img {
    width: 5vw;
    padding: 2vw;
}

.home.mobile > .banner > .splash {
    margin-top: 5vw;
}

.home.mobile > .banner > .splash > img {
    width: 100%;
}

.home.mobile > .projects,
.home.mobile > .blog
.home.mobile > .open-source {
    margin-top: 5vw;
}

.home.mobile > .projects > .top-section {
    width: 50vw;
}

.home.mobile > .blog > .top-section > .underline,
.home.mobile > .open-source > .top-section > .underline,
.home.mobile > .projects > .top-section > .underline {
    height: 1vw;
    margin-top: 2vw;
}

.home.mobile > .projects > .project-main{
    flex-direction: column-reverse;
    align-items: center;
    /* overflow: hidden; */
}

.home.mobile > .projects > .project-main > .shapes{
    width: 100%;
    height: 0vmin;
    /* height: 30vmin; */
    position: relative;
}
.home.mobile > .projects > .project-main > .shapes > img {
    height: auto;
}

.home.mobile > .projects > .project-main.is-right > .shapes{
    top: 59vw;
    left: 53vw;
}

.home.mobile > .projects > .project-main.is-left > .shapes{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    right: 60vmin;
    top: 130vw;
}

.home.mobile > .projects > .project-main > .body > .text {
    font-size: 4vw;
    padding-top: 5vw;
}

.home.mobile > .projects > .project-main > .body > .images{
    margin-bottom: 4vw;
}

.home.mobile > .projects > .project-main > .body > .images > .image-container {
    height: 8vw;
    margin-top: 4vw;
}

.home.mobile > .projects > .project-main > .phone {
    width:100%;
}

.home.mobile > .projects > .project-main > .phone > img {
    width:100%;
}

.home.mobile > .projects > .see-more-container > .see-more{
    height: 10vw;
    line-height: 10vw;
    width: 60vw;
    border-radius: 2vw;
}

.home.mobile > .blog > .top-section,
.home.mobile > .open-source > .top-section {
    width: 70vw;
    padding-top: 30px;
}

.project-separator {
    height: 2px;
    background-color: #b4b4b4;
    margin: 20px 0 40px 0;
    border-radius: 5px;
}

.home.mobile > .blog > .see-more,
.home.mobile > .open-source > .see-more{
    margin: 10vw;
    font-size: 5vw;
}

.home.mobile > .blog > .top-section {
    width: 32vw;
}

.home.mobile > .blog > .see-more{
    margin: 10vw;
    font-size: 5vw;
}


.projects-main > .body {
    margin: 4vmin;
}

.projects-main > .body > .body-top{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: center;
    align-content: center;
    margin-bottom: 2vmin;
}

.projects-main > .body > .body-top > .phone{
    position: relative;
    top: 15%;
    width: 100%;
    left: 25%;
}

.projects-main> .body > .body-top > .shapes{
    position: relative;
    width: 150%;
    right: 25%;
}

.projects-main> .body > .body-top > .phone > .image-container{
    position: relative;
    height: 150%;
    width: 0;
} 


.projects-main > .body > .body-top > .shapes > .image-container{
    position: relative;
    height: 60%;
    width: 0;
    z-index: 1;
}

.projects-main > .body > .images > .image-container {
    flex: 0 1 35%;
}

.projects-main > .body > .body-main > .title{
    font-weight: 600;
}

.projects-main > .body > .images{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 5vh;
}


.projects-main {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.projects-main.desktop{
    max-width: 70vw;
    flex: 1 1;
}

.projects-main.desktop > .body{
    width: 52vmin;
}

.projects-main.desktop > .body > .body-top {
    height: 35vmin;
    border-radius: 2vmin;
}

.projects-main.desktop > .body > .body-main > .title{
    font-size: 3vmin;
    margin-bottom: 2vmin;
}

.projects-main.desktop > .body > .body-main > .text{
    font-size: 2.2vmin;
    margin-bottom: 2vmin;
}

.projects-main.desktop > .body > .images > .image-container{
    height: 5.4vmin;
}

.projects-main.mobile > .body{
    width: 90vw;
}

.projects-main.mobile > .body > .body-top {
    height: 60vw;
    border-radius: 3vmin;
}

.projects-main.mobile > .body > .body-main > .title{
    font-size: 7vw;
    margin-bottom: 4vw;
}

.projects-main.mobile > .body > .body-main > .text{
    font-size: 4vw;
    margin-bottom: 4vw;
}

.projects-main.mobile > .body > .images > .image-container{
    height: 4.5vh;
}





.our-team-main{
    font-weight: 600;
}

.our-team-cards-main{
    display: flex;
}

.our-team-cards-main > .body{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.our-team-cards-main > .body > .position{
    color: #4BBABA
}

.our-team-cards-main > .body > .images{
    display: flex;
    width: 100%;
}

.our-team-cards-main > .body > .shapes{
    height: 0;
}

.our-team-cards-main > .body > .shapes > .image-container{
    position: relative;
}

.our-team-cards-main > .body > .shapes > .image-container > img{
    width: 100%;
}

.our-team-cards-main > .body > .images > .image-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}





.our-team-cards-main.desktop{
    flex-wrap: wrap;
}

.our-team-main.desktop > .header{
    font-size: 4vmin;
    padding: 2vmin;
    height: 10vmin;
}

.our-team-cards-main.desktop > .body{
    padding: 2vmin;
}

.our-team-cards-main.desktop > .body > .name{
    font-size: 2vmin;
    margin: 1vmin;
}

.our-team-cards-main.desktop > .body > .position{
    font-size: 1.6vmin;
    margin: 1vmin;
}

.our-team-cards-main.desktop > .body > .photo > .image-container{
    border-radius: 3vmin;
    overflow: hidden;
    height: 23vmin;
    width: 23vmin;
}

.our-team-cards-main.desktop > .body > .shapes > .image-container{
    bottom: 12vmin;
    width: 23vmin;
}

.our-team-cards-main.desktop > .body > .images{
    height: 3vmin;
    margin: 1vmin;
    justify-content: space-evenly;
}

.our-team-cards-main.desktop > .body > .images > .image-container{
    height: 100%;
}
.our-team-cards-main.mobile{
    flex-direction: column;
}

.our-team-main.mobile > .header{
    font-size: 8vw;
    padding: 4vw;
}

.our-team-cards-main.mobile > .body{
    padding: 4vw;
    width: 85vw;
}

.our-team-cards-main.mobile > .body > .name{
    font-size: 6vw;
    margin: 3vw;
}

.our-team-cards-main.mobile > .body > .position{
    font-size: 4.8vw;
    /* margin: 2vw; */
}

.our-team-cards-main.mobile > .body > .photo{
    width: 100%;
    /* height: 32vh; */
    overflow: hidden;
    border-radius: 5vw;
    object-fit: contain;
}

.our-team-cards-main.mobile > .body > .photo > .image-container{
    position: relative;
    border-radius: 5vw;
    overflow: hidden;
    /* bottom: 3vw; */
    object-fit: contain;
    /* height: 100%; */
}


.our-team-cards-main.mobile > .body > .shapes > .image-container{
    bottom: 33.1vw;
    width: 50vw;
}

.our-team-cards-main.mobile > .body > .photo > .image-container > img{
    width: 100%;
}

.our-team-cards-main.mobile > .body > .images{
    height: 9vw;
    margin: 2vw;
    justify-content: center;
}

.our-team-cards-main.mobile > .body > .images > .image-container{
    margin: 1vw;
}


img{
    display: block;
    height: 100%;
}

html, body, #root{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

textarea:focus, input:focus{
    outline: 0;
}

.app-main.desktop{
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    height: 100%;
}


