.home.desktop{
    /* padding-top: 4vmin; */
    width: 50vmin;
    max-width: 120vmin;
    flex: 1;
}

.home.desktop > .banner {
    flex-direction: row;
    padding: 2vmin;
    justify-content: space-around;
    flex-wrap: wrap;
    flex: 0 0 auto;
}

.home.desktop > .blog > .top-section > .header,
.home.desktop > .open-source > .top-section > .header,
.home.desktop > .projects > .top-section > .header,
.home.desktop > .banner > .body > .header {
    font-size: 4vmin;
}

.home.desktop > .banner > .body {
    width: 40vmin;
    min-height: 35vmin;
}

.home.desktop > .banner > .buffer{
    display: flex;
    flex: 1;
    max-width: 30vmin;
}

.home.desktop > .bullet-point-section {
    padding: 3vmin 5vmin;
}


.home.desktop > .projects > .project-main > .body > .title, 
.home.desktop > .bullet-point-section > .bullet-point-header {
    font-size: 3vmin;
}

.home.desktop > .bullet-point-section > .bullet-points > .bullet-point {
    font-size: 1.8vmin;
    line-height: 3vmin;
    display: flex;
}

.home.desktop > .bullet-point-section > .bullet-points > .bullet-point {
    line-height: 4vmin;
}

.home.desktop > .bullet-point-section > .bullet-points > .bullet-point > img {
    width: 2vmin;
    padding: 1vmin;
}

.home.desktop > .bullet-point-section > .bullet-points > .bullet-point > .point {
    line-height: 4vmin;
}

.home.desktop > .banner > .splash {
    /* margin-left: 20vmin; */
    /* display: flex; */
    height: 45vmin;
    /* flex: 1; */
}

.home.desktop > .blog,
.home.desktop > .open-source {
    padding: 2vmin;
    margin: 2vmin;
    width: auto;
}


.home.desktop > .projects{
    padding: 2vmin;
    margin: 2vmin;
    max-width: 60vw;
}

.home.desktop > .projects > .top-section {
    width: 22vmin;
}

.home.desktop > .blog > .top-section > .underline,
.home.desktop > .open-source > .top-section > .underline,
.home.desktop > .projects > .top-section > .underline {
    height: 0.4vmin;
    margin-top: 1vmin;
}


.home.desktop > .projects > .project-main{
    height: 50vmin;
    max-width: 100vmin;
    justify-content: center;
    margin: 5vmin;
}


.home.desktop > .projects > .project-main.is-left{
    flex-direction: row-reverse;
}

.home.desktop > .projects > .project-main.is-right{
    flex-direction: row;
}

.home.desktop > .projects > .project-main > .shapes{
    width: 0vmin;
    height: 30vmin;
    position: relative;
}

.home.desktop > .projects > .project-main.is-right > .shapes{
    right: 8vmin;
    top: 14vmin;
}

.home.desktop > .projects > .project-main.is-left > .shapes{
    transform: rotate(180deg);
    left: 13.5vmin;
    top: 10vmin;
}

.home.desktop > .projects > .project-main > .body {
    width: 50vmin;
    padding: 5vmin;
}


.home.desktop > .projects > .project-main > .body > .text {
    font-size: 1.7vmin;
}

.home.desktop > .projects > .project-main > .body > .images > .image-container {
    height: 5vmin;
}

.home.desktop > .projects > .project-main > .phone {
    width: 20vmin;
    padding-left: 5vmin;
    padding-right: 5vmin;
}


.home.desktop > .projects > .see-more-container > .see-more{
    font-size: 1.7vmin;
    height: 4vmin;
    line-height: 4vmin;
    width: 22vmin;
    border-radius: 1vmin;
    cursor: pointer;
}
.home.desktop > .open-source > .top-section{
    width: 30vmin;
}

.home.desktop > .open-source > .see-more{
    margin: 4vmin;
    font-size: 2vmin;
    cursor: pointer;
}

.home.desktop > .blog > .top-section{
    width: 15vmin;
}

.home.desktop > .blog > .see-more{
    margin: 5vmin;
    font-size: 2vmin;
    cursor: pointer;
}






