.navbar.mobile{
    background-color:#3F3D56;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    /* height: 9%; */
    height: 20vw;
}

.navbar.mobile > .logo{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar.mobile > .logo > .image-container{
    height: 12vw;
    /* height: 6vh; */
    margin: 2vw;
    padding-left: 2vw;
}

.navbar.mobile > .logo > .title{
    display: flex;
    flex-wrap: wrap;
    width: 10vh;
    color: white;
    line-height: 5vw;
    font-size: 5.6vw;
}

.navbar.mobile > .sidebar-icon {
    font-size: 8vw;
    /* margin: 2vw; */
    color: white;
    padding: 6vw;
}