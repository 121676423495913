.our-team-main{
    font-weight: 600;
}

.our-team-cards-main{
    display: flex;
}

.our-team-cards-main > .body{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.our-team-cards-main > .body > .position{
    color: #4BBABA
}

.our-team-cards-main > .body > .images{
    display: flex;
    width: 100%;
}

.our-team-cards-main > .body > .shapes{
    height: 0;
}

.our-team-cards-main > .body > .shapes > .image-container{
    position: relative;
}

.our-team-cards-main > .body > .shapes > .image-container > img{
    width: 100%;
}

.our-team-cards-main > .body > .images > .image-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}




