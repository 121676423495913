.home {
    display: flex;
    flex-direction: column;
    font-family: 'Source Sans Pro', sans-serif;
}

.home > .banner,
.home > .projects > .project-main {
    display: flex;
}

.home > .banner > .body > .header,
.home > .banner > .body > .bullet-point-section > .bullet-point-header,
.home > .projects > .top-section > .header,
.home > .open-source > .top-section > .header,
.home > .blog > .top-section > .header,
.home > .projects > .project-main > .body > .title{
    font-weight: 600;
}

.home > .banner > .body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.home > .banner > .body > .header {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.home > .banner > .body > .bullet-point-section {
    display: flex;
    flex: 1.8;
    flex-direction: column;
    justify-content: space-evenly
}

.home > .banner > .body > .bullet-point-section > .bullet-points > .bullet-point {
    display: flex;
    flex-direction: row;
}

.home > .blog > .top-section > .underline,
.home > .open-source > .top-section > .underline,
.home > .projects > .top-section > .underline {
    display: flex;
    flex-direction: row;
}

.home > .blog > .top-section > .underline > .left,
.home > .open-source > .top-section > .underline > .left, 
.home > .projects > .top-section > .underline > .left{
    height: 100%;
    background-color: #3F3D56;
}

.home > .open-source > .top-section > .underline > .left{
    flex: 3;
}
.home > .projects > .top-section > .underline > .left{
    flex: 2;
}
.home > .blog > .top-section > .underline > .left{
    flex: 1.5;
}

.home > .blog > .top-section > .underline > .right,
.home > .open-source > .top-section > .underline > .right,
.home > .projects > .top-section > .underline > .right{
    height: 100%;
    background-color: #FF8A64;
    flex: 1
}

.home > .projects > .project-main > .phone{
    z-index: 1;
}

.home > .projects > .project-main > .body{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
    min-width: 30vmin;
}

.home > .projects > .project-main > .body > .images {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
}

.home > .projects > .project-main > .body > .images > .image-container{
    flex: 0 1 30%;
    padding: 0.7vmin;
    /* flex-wrap: wrap; */
}

.home > .projects > .see-more-container{
    display: flex;
    justify-content: center;
}

.home > .projects > .see-more-container > .see-more{
    background-color: #43CEAF;
    color: white;
    text-align: center;
}

.home > .blog > .see-more,
.home > .open-source > .see-more{
    color: #43CEAF;
    font-weight: 600;
} 






