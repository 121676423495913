
.blog-card-main,
.blog-card-main > .body, 
.blog-card-main > .body > .body-main,
.blog-card-main > .body > .body-right > .text {
    display: flex;
}

.blog-card-main > .body > .body-main > .title{
    font-weight: 600
}

.blog-card-main > .body > .body-main > .name{
    color: #FF8A64
}

.blog-card-main.desktop{
    justify-content: space-between;
    padding-top: 2vmin;
    flex-direction: row;
}

.blog-card-main > .body > .body-right > .text{
    flex-direction: row;
}

.blog-card-main > .body > .body-right > .text > .text-container{
    background-color: #FFEAE4;
    color: #FF8A64;
}


.blog-card-main > .body > .body-right > .go-to{
    color: #43CEAF;
}

.blog-main.desktop{
    width: 50vmin;
    max-width: 120vmin;
    flex: 1;
}

.blog-card-main.desktop > .body > .body-right > .go-to{
    padding-right: 2.1vmin;
    text-align: end;
}

.blog-card-main.desktop> .body{
    justify-content: space-between;
    width: 100%;
}

.blog-card-main.desktop> .logo {
    height: 8vmin;
}

.blog-card-main.desktop> .logo > .image-container{
    height: 100%;
    padding-left: 1vmin;
    padding-right: 2vmin;
}

.blog-card-main.desktop > .body > .body-main{
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.5vmin;
}

.blog-card-main.desktop > .body > .body-right{
    font-size: 1.5vmin;
}

.blog-card-main.desktop > .body > .body-right > .text > .text-container{
    margin-right: 2vmin;
    margin-bottom: 1vmin;
    padding-left: 1vmin;
    padding-right: 1vmin;
    padding-top: 0.5vmin;
    padding-bottom: 0.5vmin;
    border-radius: 0.7vmin;
}

.blog-card-main.desktop > .body > .body-right > .go-to{
    padding-right: 2.1vmin;
    text-align: end;
}

.blog-card-main.mobile{
    flex-direction: column;
    justify-content: space-between;
    padding-top: 5vw;
    padding-left: 2vw;
    padding-right: 5vw;
}

.blog-card-main.mobile > .body{
    flex-direction: column;
}

.blog-card-main.mobile > .body > .body-main{
    flex-direction: column;
}

.blog-card-main.mobile > .body > .body-main > .name{
    margin-top: 2vw;
}

.blog-card-main.mobile > .body > .body-right{
    font-size: 3.2vw;
}

.blog-card-main.mobile > .body > .body-right > .text > .text-container{
    margin-right: 3vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
    padding: 2vw;
    border-radius: 2vw;
}

.blog-card-main.mobile > .logo{
    padding-top: 1vw;
}

.blog-card-main.mobile > .logo > .image-container{
    height: 18vw;
    margin-bottom: 3vw;
}


