.search-area {
    display: flex;
    flex-direction: row;
    height: inherit;
    line-height: inherit;
}

.search-area > .input{
    display: flex;
    flex: 1;
    border-width: 0;
    font-size: inherit;
    height: inherit;
    line-height: inherit;
    
}

.search-area > .input:focus{
    outline: none;
}

.search-bar.desktop{
    padding-top: 2vmin;
    padding-bottom: 2vmin;
    padding-left: 1vmin;
    padding-right: 2vmin;
    line-height: 3vmin;
}

.search-area.desktop{
    font-size: 2.5vmin;
    border-radius: 0.5vmin;
    box-shadow: 0px 0px 0.5vmin #000000;
}

.search-area.desktop > .text{
    padding: 1vmin;
}

.search-area.desktop > .button{
    background-color: #43CEAF;
    border-radius: 0 0.5vmin 0.5vmin 0;
    width: 5vmin;
}

.search-area.desktop > .button > .search-icon{
    color: white;
    padding: 1vmin;
}

.search-bar.mobile{
    padding: 4vw;
    line-height: 6vw;
}

.search-area.mobile{
    font-size: 4vw;
    border-radius: 1vw;
    box-shadow: 0px 0px 1vw #000000;
}

.search-area.mobile > .text{
    padding: 2vw;
}

.search-area.mobile > .button{
    background-color: #43CEAF;
    border-radius: 0 1vw 1vw 0;
    width: 9vw;
}

.search-area.mobile > .button > .search-icon{
    color: white;
    padding: 2vw;
}
