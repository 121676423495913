.open-source-card-main,
.open-source-card-main > .body, 
.open-source-card-main > .body > .body-main, 
.open-source-card-main > .images{
    display: flex;
}

.open-source-card-main > .body > .body-main > .title{
    font-weight: 600
}


.open-source-card-main >  .images{
    flex-direction: row;
}


.open-source-card-main > .images > .star {
    display: flex;
}

.open-source-card-main > .images > .star > .icon{
    color: #FCB059;
}

.open-source-card-main{
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vmin;
    flex: 1;
}

.open-source-main.desktop{
    width: 50vmin;
    max-width: 120vmin;
    flex: 1;
}

.open-source-card-main.desktop {
    flex-direction: row;
    height: 5vmin;
}

.open-source-card-main.desktop > .body > .side-icon > .image-container{
    height: 3vmin;
    width: 4vmin;
    padding-left: 1vmin;
    padding-right: 1vmin;
}

.open-source-card-main.desktop > .body > .body-main{
    flex-direction: column;
    justify-content: center;
    font-size: 1.5vmin;
    height: 3vmin;
}

.open-source-card-main.desktop > .images{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 5vmin;
    flex-basis: 35%;
}

.open-source-card-main.desktop > .images > .star > .text{
    line-height: 5vmin;
    font-size: 2vmin;
}

.open-source-card-main.desktop > .images > .star > .icon{
    font-size: 2vmin;
    line-height: 5vmin;
    width: 3vmin;
}

.open-source-card-main.desktop> .images > .image-container{
    padding: 1vmin;
}

.open-source-card-main.mobile{
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2vw;
}

.open-source-card-main.mobile > .body{
    flex-direction: row;
    /* height: 20vw; */
}

.open-source-card-main.mobile > .body > .body-main{
    flex-direction: column;
}

.open-source-card-main.mobile > .body > .side-icon{
    width: 12vw;
    padding-top: 1vw;
}

.open-source-card-main.mobile > .body > .side-icon > .image-container{
    height: 6vw;
    padding: 2vw;
}

.open-source-card-main.mobile > .images{
    height: 6vw;
    padding-left: 12vw;
}

.open-source-card-main.mobile > .images > .star > .icon{
    font-size: 4vw;
    width: 6vw;
}

.open-source-card-main.mobile > .images > .star > .text{
    padding-right: 8vw;
}

.open-source-card-main.mobile> .images > .image-container{
    height: 100%;
    padding-left: 10vw;
}


