.navbar.desktop{
    display: flex;
    flex-direction: row;
    height: 7vh;
    align-items: center;
}

.navbar.desktop > .blast-logo{
    display: flex;
    flex-direction: row;
    background-color: #2D2B43;
    width: 29.3vmin;
    height: 100%;
    align-items: center;
    /* text-align: center; */
}

.navbar.desktop > .blast-logo > .image-container{
    height: 5vh;
    padding: 1vh;
    margin-left: 1vh;
    /* margin-right: 1vmin; */
}

.navbar.desktop > .blast-logo > .title{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: white;
    font-size: 2.2vh;
    line-height: 2.2vh;
    width: 4vw;
}

.navbar.desktop > .right-side {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex: 1;
    height: 7vh;
    /* line-height: 6vh; */
    border-bottom: #DCDCDC 0.1vmin solid;
}

.navbar.desktop > .right-side > .left-buffer{
    display: flex;
    flex: 1;
    max-width: 60vmin;
}

.navbar.desktop > .right-side > .right-buffer{
    display: flex;
    max-width: 40vmin;
}

.navbar.desktop > .right-side > .affiliates{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 20vh;
    height: 3vh;
}