.home.mobile{
    padding: 8vw;
}

.home.mobile > .banner {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.home.mobile > .open-source > .top-section > .header,
.home.mobile > .blog > .top-section > .header,
.home.mobile > .projects > .top-section > .header,
.home.mobile > .banner > .body > .header {
    font-size: 9vw;
}

.home.mobile > .open-source:last-child {
    padding-bottom: 30px;
}

.home.mobile > .projects > .top-section {
    margin-bottom: 30px;
}

.home.mobile > .bullet-point-section{
    margin-top: 10vw;
}

.home.mobile > .projects > .project-main > .body > .title, 
.home.mobile > .bullet-point-section > .bullet-point-header {
    font-size: 5vw;
    padding-top: 20px;
}

.home.mobile > .bullet-point-section > .bullet-points > .bullet-point {
    font-size: 4.5vw;
    line-height: 9vw;
    display: flex;
    padding: 10px 0;
}

.home.mobile > .bullet-point-section > .bullet-points > .bullet-point > img {
    width: 5vw;
    padding: 2vw;
}

.home.mobile > .banner > .splash {
    margin-top: 5vw;
}

.home.mobile > .banner > .splash > img {
    width: 100%;
}

.home.mobile > .projects,
.home.mobile > .blog
.home.mobile > .open-source {
    margin-top: 5vw;
}

.home.mobile > .projects > .top-section {
    width: 50vw;
}

.home.mobile > .blog > .top-section > .underline,
.home.mobile > .open-source > .top-section > .underline,
.home.mobile > .projects > .top-section > .underline {
    height: 1vw;
    margin-top: 2vw;
}

.home.mobile > .projects > .project-main{
    flex-direction: column-reverse;
    align-items: center;
    /* overflow: hidden; */
}

.home.mobile > .projects > .project-main > .shapes{
    width: 100%;
    height: 0vmin;
    /* height: 30vmin; */
    position: relative;
}
.home.mobile > .projects > .project-main > .shapes > img {
    height: auto;
}

.home.mobile > .projects > .project-main.is-right > .shapes{
    top: 59vw;
    left: 53vw;
}

.home.mobile > .projects > .project-main.is-left > .shapes{
    transform: rotate(180deg);
    right: 60vmin;
    top: 130vw;
}

.home.mobile > .projects > .project-main > .body > .text {
    font-size: 4vw;
    padding-top: 5vw;
}

.home.mobile > .projects > .project-main > .body > .images{
    margin-bottom: 4vw;
}

.home.mobile > .projects > .project-main > .body > .images > .image-container {
    height: 8vw;
    margin-top: 4vw;
}

.home.mobile > .projects > .project-main > .phone {
    width:100%;
}

.home.mobile > .projects > .project-main > .phone > img {
    width:100%;
}

.home.mobile > .projects > .see-more-container > .see-more{
    height: 10vw;
    line-height: 10vw;
    width: 60vw;
    border-radius: 2vw;
}

.home.mobile > .blog > .top-section,
.home.mobile > .open-source > .top-section {
    width: 70vw;
    padding-top: 30px;
}

.project-separator {
    height: 2px;
    background-color: #b4b4b4;
    margin: 20px 0 40px 0;
    border-radius: 5px;
}

.home.mobile > .blog > .see-more,
.home.mobile > .open-source > .see-more{
    margin: 10vw;
    font-size: 5vw;
}

.home.mobile > .blog > .top-section {
    width: 32vw;
}

.home.mobile > .blog > .see-more{
    margin: 10vw;
    font-size: 5vw;
}
