.frame.mobile {
  width: 170vw;
  /* left: 100vw; */
  position: relative;
  transition: transform 0.4s;
  height: 92%;
}

.frame.mobile.sidebar-open {
  transform: translate(-70vw)
}

.frame.mobile > .overlay{
  z-index: 1;
  /* background-color: green; */
  width: 100%;
  position: absolute;
  height: 100%;
}

.frame.mobile > .sidebar-container {
  width: 70vw;
  /* background: blue; */
  display:flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
}

.frame.mobile > .non-sidebar {
  width: 100vw;
}

