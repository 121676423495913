.our-team-cards-main.mobile{
    flex-direction: column;
}

.our-team-main.mobile > .header{
    font-size: 8vw;
    padding: 4vw;
}

.our-team-cards-main.mobile > .body{
    padding: 4vw;
    width: 85vw;
}

.our-team-cards-main.mobile > .body > .name{
    font-size: 6vw;
    margin: 3vw;
}

.our-team-cards-main.mobile > .body > .position{
    font-size: 4.8vw;
    /* margin: 2vw; */
}

.our-team-cards-main.mobile > .body > .photo{
    width: 100%;
    /* height: 32vh; */
    overflow: hidden;
    border-radius: 5vw;
    object-fit: contain;
}

.our-team-cards-main.mobile > .body > .photo > .image-container{
    position: relative;
    border-radius: 5vw;
    overflow: hidden;
    /* bottom: 3vw; */
    object-fit: contain;
    /* height: 100%; */
}


.our-team-cards-main.mobile > .body > .shapes > .image-container{
    bottom: 33.1vw;
    width: 50vw;
}

.our-team-cards-main.mobile > .body > .photo > .image-container > img{
    width: 100%;
}

.our-team-cards-main.mobile > .body > .images{
    height: 9vw;
    margin: 2vw;
    justify-content: center;
}

.our-team-cards-main.mobile > .body > .images > .image-container{
    margin: 1vw;
}

