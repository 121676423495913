.sidebar.desktop{
  width: 29.3vmin;
}

.sidebar > .copyright{
  font-weight: 600;
  color: #B0B0CB;
  text-align: center;
}

.sidebar.desktop > .switch{
  margin-top: 2vh;
  height: 35vh;
}

.sidebar.desktop > .switch > .body{
  padding: 2.5vh;
  cursor: pointer;
}

.sidebar.desktop > .switch > .body > .image-container{
  height: 3vmin;
}

.sidebar.desktop > .switch > .body > .text{
  padding-left: 2vmin;
  line-height: 3vmin;
  font-size: 2vmin;
  font-weight: 600;
}

.sidebar.desktop > .project-request{
  padding: 2.5vh;
  margin-top: 5vh;
  height: 40vh;
}

.sidebar.desktop .project-request > .header{
  font-size: 2.2vmin;
  font-weight: 600;
}

.sidebar.desktop > .project-request > .header > .underline{
  height: 0.6vh;
  padding-top: 1vh;
  padding-bottom: 2.5vh;
}

.sidebar.desktop > .project-request > .email{
  display: flex;
  border-radius: 0.6vh;
  font-size: 1.7vmin;
  padding: 1vh;
  height: 3vmin;
  line-height: 3vmin;
  width: 90%;
  margin-bottom: 2.5vh
}

.sidebar.desktop > .project-request > .message{
  display: flex;
  border-radius: 0.6vh;
  font-size: 1.7vmin;
  padding: 1vh;
  line-height: 3vmin;
  width: 90%;
  flex: 1;
  margin-bottom: 2.5vh
}

.sidebar.desktop > .project-request > .contact-us{
  border-radius: 0.6vh;
  padding: 1vh;
  height: 3vh;
  line-height: 3vh;
  font-size: 1.7vmin;
  width: 90%;
}

.sidebar.desktop > .project-request > .contact-us > .image-container{
  height: 2vh;
  line-height: 3vh;
}

.sidebar.desktop .project-request > .submit-circle{
  height: 15vh;
  width: 15vh;
  border-radius: 7.5vh;
}

.sidebar.desktop .project-request > .submit-circle > .image-container{
  height: 4vh;
}

.sidebar.desktop > .project-request > .in-contact{
  font-size: 1.4vmin;
}

.sidebar.desktop > .affiliates {
  height: 0;
  overflow: hidden;
}

.sidebar.desktop > .copyright{
  padding-top: 2vh;
  font-size: 1.4vmin;
}